import { onFind } from "@elements/init-modules-in-scope";
import { on, find } from "@elements/dom-utils";

const defaultSelectors = {
    base: '.js-skip-links'
};

export function init (selectors = defaultSelectors) {

    onFind(selectors.base, (skiplink) => {
        on('click', (e) => {
            let target = find(skiplink.getAttribute('data-href'));

            window.scrollTo(window.scrollX, target.offsetTop);
            target.focus();
        }, skiplink);
    });

}