'use strict';

import {find, findAllIn, closest, findIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from '@elements/data-set-utils';

let defaultOptions = {
    scrollOffset: 0,
    initialPosition: false
};
let defaultSelectors = {
    base: '.js-smoothscroll',
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {

    onFind(selectors.base, function (baseElement) {
        createSmoothScroll(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

}

export function createSmoothScroll(smoothScroll, options = defaultOptions, selectors = defaultSelectors) {

    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('smoothscroll', smoothScroll)
    };


    const anchors = findAllIn(defaultSelectors.link, smoothScroll);

    if (elementOptions.initialPosition){
        setTimeout(function() {
            setInitialPosition(smoothScroll, options, selectors);
        }, 1);
    }

    anchors.forEach(element => {
        element.addEventListener('click', function (evt) {
            evt.preventDefault();
            const
                anchor = element,
                target =  findIn(element.hash, smoothScroll) || find(element.hash);

            let offset = anchor.dataset.scrollOffset ?  anchor.dataset.scrollOffset : elementOptions.scrollOffset;

            if (!target) {
                console.warn(`Could not find target ${element.hash} for element `, element);
                return;
            }

            smoothscroll(element, target, offset, selectors, anchor.offsetTop)
        })
    });
}

export function setOffset(offset) {
    defaultOptions.scrollOffset = offset;
}

export function smoothscroll(el, target, offset = defaultOptions.scrollOffset, selectors, anchorOffset) {
    const tabPaneParent = closest('.tab-pane:not(.active)', target);
    const collapseParent = closest('.collapse', target);

    // TODO: Tab/Collapse funktion mit Bootstrap native?

    // if(target.dataset.toggle === 'tab'){
    //     scrollTo(el, target, offset, anchorOffset);
    //
    //     //???? bootstrap without jquery ????
    //     $(target).tab('show');
    //     $(target).one('shown.bs.tab', function () {
    //         scrollTo(el, target, offset, anchorOffset);
    //     });
    //
    // } else if (tabPaneParent) {
    //     /* anchor in tab */
    //     const $tabTrigger = $('[href="#' + tabPaneParent.getAttribute('id') + '"],[data-toggle=tab][data-target="#' + tabPaneParent.getAttribute('id') + '"]');
    //
    //     $tabTrigger.tab('show');
    //     $tabTrigger.one('shown.bs.tab', function () {
    //         scrollTo(el, target, offset, anchorOffset)
    //     });
    //
    // } else if(target.dataset.toggle === 'collapse'){
    //     if(hasClass('collapsed', target)){
    //         const collapseTrigger = $(target.dataset.target);
    //         collapseTrigger.collapse('show');
    //         collapseTrigger.one('shown.bs.collapse', function () {
    //             scrollTo(el, target, offset, anchorOffset)
    //         });
    //     }else{
    //         scrollTo(el, target, offset, anchorOffset)
    //     }
    //
    // } else if (collapseParent) {
    //     /* anchor in accordion */
    //
    //     if(!hasClass('show', collapseParent)){
    //         $(collapseParent).collapse('show');
    //         $(collapseParent).one('shown.bs.collapse', function () {
    //             scrollTo(el, target, offset, anchorOffset)
    //         });
    //     }else{
    //         scrollTo(el, target, offset, anchorOffset)
    //     }
    //
    // } else {
    //     // no anchor in hidden tab or accordion
    //     scrollTo(el, target, offset, anchorOffset)
    // }

    scrollTo(el, target, offset, anchorOffset)


}

function scrollTo(el, target, offset, anchorOffset) {
    // let time = target.offsetTop - anchorOffset > 0 ? (target.offsetTop - anchorOffset)/3 : ((target.offsetTop - anchorOffset)*-1)/3;

    let scrollOffset = target.offsetTop - offset;

    const fixedOffset = scrollOffset.toFixed(),
        onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                target.focus();
                if(el){
                    history.replaceState({}, '', el.hash);
                }
                if (target === document.activeElement) {
                    return false;
                } else {
                    target.focus();
                }
            }
        };

    window.addEventListener('scroll', onScroll);
    window.scrollTo({
        top: scrollOffset,
        behavior: 'smooth'
    });

}

function setInitialPosition(scope, options, selectors) {
    let hash = window.location.hash;
    if(hash) {
        const
            pattern = new RegExp(hash),
            anchors = findAllIn(selectors.link, scope),
            target = find(hash);

        let offset = options.scrollOffset;

        anchors.forEach((el) => {
            offset = pattern.test(el.getAttribute('href')) && el.getAttribute('data-'+selectors.dataOffset) ? el.getAttribute('data-'+selectors.dataOffset) : offset;
        });

        smoothscroll(this, target, offset, defaultSelectors, 0)
    }
}
