export default function throwError(...params) {
    // always log the error because the thrown error could be swallowed if we are inside a promise
    console.error(...params);

    if (process.env.NODE_ENV === 'development') {
        let errorMessage = params.map(x => typeof x === 'object' ? JSON.stringify(x, null, '  '): x).join(', ');

        // use dynamic import to prevent circular dependencies
        import('@elements/alert-notification').then(function ({showNotification}) {
            showNotification({
                type: 'danger',
                title: 'Debug Error',
                content: errorMessage,
            });
        })

        throw new Error(errorMessage);
    }
}
export function throwWarning(...params) {
    if (process.env.NODE_ENV === 'development') {
        console.warn(...params);

        let warningMessage = params.map(x => typeof x === 'object' ? JSON.stringify(x, null, '  '): x).join(', ');

        // use dynamic import to prevent circular dependencies
        import('@elements/alert-notification').then(function ({showNotification}) {
            showNotification({
            type: 'warning',
            title: 'Debug Warning',
                content: warningMessage,
            });
        })
    }
}