"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import throwError from "@elements/throw-error";
import {onFind} from "@elements/init-modules-in-scope";
import {is, findAllIn, setAttribute, closest} from "@elements/dom-utils";


const defaultSelectors = {
    base: '.js-lazy-img',
    backgroundModifier: '.js-lazy-img--bg'
};

const defaultOptions = {
    offset: window.innerHeight / 2,
    preventNative: false
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createLazyImage(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export default init;

export function createLazyImage(image, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...transformDataSetOptions(getPrefixedDataSet('lazy-img', image))
    };

    selectors = {...defaultSelectors, ...selectors};

    image = closest('picture', image) || image;

    if ('loading' in HTMLImageElement.prototype && !options.preventNative) {
        if (is(selectors.backgroundModifier, image)) {
            onEnterViewPort(image, function (image) {
                loadImg(image, options, selectors);
            }, options);
        } else {
            switchImage(image, true);
        }
    } else {
        onEnterViewPort(image, function (image) {
            loadImg(image, selectors, options);
        }, options);
    }
}

export function loadImg(image, passedOptions = {}, selectors = {}) {
    selectors = {...defaultSelectors, ...selectors};

    let options = {
        ...defaultOptions,
        ...transformDataSetOptions(getPrefixedDataSet('lazy-img', image)),
        ...passedOptions,
    };

    if (image.matches(selectors.backgroundModifier)) {
        if (options.mediaQueryBackgrounds) {
            if (typeof options.mediaQueryBackgrounds !== "object") {
                throwError(mediaQueryBackgroundErrorMessage);
                return;
            }

            let mqs = Object.keys(options.mediaQueryBackgrounds);

            const setCurrentlyMatchingBackground = () => {
                let result = [...mqs].reverse().find(mq => mq === 'default' || matchMedia(mq).matches);

                setBackgroundImage(image, result
                    ? options.mediaQueryBackgrounds[result]
                    : options.background
                );
            };

            setCurrentlyMatchingBackground();

            mqs.filter(x => x !== 'default').forEach(mq => {
                // Use addListener instead of addEventListener because of IE
                matchMedia(mq).addListener(() => {
                    setCurrentlyMatchingBackground();
                });
            })
        } else {
            // single background image
            setBackgroundImage(image, options.background);
        }
    } else {
        // usual image
        switchImage(image);

        if (!window.HTMLPictureElement) {
            import('picturefill').then(function () {
                picturefill({
                    reevaluate: true
                });
            });
        }
    }
}

function setBackgroundImage(image, imageSrc) {
    image.style.backgroundImage = imageSrc
        ? `url("${imageSrc}")`
        : '';
}

function switchImage(image, addLazyLoading = false) {
    if (image.matches('picture')) {
        findAllIn('source, img', image).forEach(function (element) {
            if (addLazyLoading) {
                findAllIn('img', image)
                    .map(setAttribute('loading', 'lazy'))
            }

            if (element.dataset.srcset) {
                element.setAttribute('srcset', element.dataset.srcset);
            }

            if (element.dataset.src) {
                element.setAttribute('src', element.dataset.src);
            }else if(element.tagName === 'IMG' && element.dataset.srcset){
                element.setAttribute('src', element.dataset.srcset.split(' ')[0]);
            }
        });
    } else {
        if (addLazyLoading) {
            image.setAttribute('loading', 'lazy');
        }

        if (image.dataset.srcset) {
            image.setAttribute('srcset', image.dataset.srcset);
        }

        if (image.dataset.src) {
            image.setAttribute('src', image.dataset.src);
        }else if(image.dataset.srcset){
            image.setAttribute('src', image.dataset.srcset.split(' ')[0]);
        }
    }
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.mediaQueryBackgrounds) {
        try {
            transformedOptions.mediaQueryBackgrounds = JSON.parse(transformedOptions.mediaQueryBackgrounds)
        } catch (e) {
            transformedOptions.mediaQueryBackgrounds = null;
            throwError(mediaQueryBackgroundErrorMessage);
        }
    }

    return transformedOptions;
}

const mediaQueryBackgroundErrorMessage = `Lazy loading mediaQueryBackgrounds error: data-media-query-backgrounds has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.
Valid Example: 
data-lazy-img-media-query-backgrounds='{
    "(min-width: 768px)": "http://placehold.it/1900x500?text=(min-width: 768px)",
    "(min-width: 1200px)": "http://placehold.it/1900x500?text=(min-width: 1200px)"
 }' `;