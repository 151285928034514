// @ts-ignore
import { onFind } from "@elements/init-modules-in-scope";
const defaultSelectors = {
    base: '.js-consent',
    template: '.js-consent__template',
    dataset: 'consent',
    button: '.js-consent__accept-btn',
};
const defaultOptions = {
    cmpTool: 'cookiebot',
    revalidateEvent: 'CookiebotOnConsentReady'
};
export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        if (options.cmpTool === 'cookiebot') {
            import("./src/Cookiebot").then((module) => {
                const { createCookiebotOverlay } = module;
                createCookiebotOverlay(baseElement, { ...defaultOptions, ...options }, { ...defaultSelectors, ...selectors });
            });
        }
        else {
            console.log('cmpTool has not been defined yet');
        }
        //add more cmpTool specific functions above else statement
    });
}
