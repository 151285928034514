import initModulesInScope, {cleanUpInScope} from "@elements/init-modules-in-scope";
import {clearAll, showNotification} from "@elements/alert-notification";
import {throwWarning} from "@elements/throw-error";
import {append, empty, removeAttribute, setAttribute, toHTMLElementArray} from "@elements/dom-utils";

export default function asyncAppend({
    target,
    loading = [],
    notifications = [],
    options: {allowScriptTags = false} = {}
}, request) {
    loading = toHTMLElementArray(loading);
    notifications = toHTMLElementArray(notifications);

    // Unpack json response body if the promise was created via fetch
    request = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    let allTargets = (target instanceof HTMLElement || Array.isArray(target))
        ? toHTMLElementArray(target)
        : Object.values(target).flatMap(toHTMLElementArray);

    // target could be an HTMLElement, array or object (like {id: element, id2: element2, id3: [element4, element5]})
    let targetsByResultId = (target instanceof HTMLElement || Array.isArray(target))
        ? {default: target}
        : target;

    // making sure target values are always arrays
    targetsByResultId = Object.entries(targetsByResultId).reduce(function (obj, [key, val]) {
        return {...obj, [key]: Array.isArray(val) ? val : [val]}
    }, {});

    allTargets.map(removeAttribute('hidden'));
    loading.map(removeAttribute('hidden'));

    if (hasElements(notifications)) {
        clearAll({
            container: notifications
        });
    }

    // always show notification even if no specific notification container is provided
    showNotification(request, {
        container: notifications
    });

    request.catch((error, requestState) => {
        loading.map(setAttribute('hidden', true));
    });

    return request
        .then(
            // Unpack json response body if the promise was created via fetch
            response => (response
                && response.json
                && typeof response.json === 'function'
                && response.clone
                && typeof response.clone === 'function')
                ? response.clone().json()
                : response
        )
        .then(function (result) {
            let content = result.html || result.content;

            if (content && result.success !== false) {
                let contentByResultId = typeof content === 'string'
                    ? {default: content}
                    : content;

                Object.entries(contentByResultId).forEach(([resultId, result]) => {

                    if (targetsByResultId[resultId]) {
                        if (resultId === 'default') {
                            allTargets.map(cleanUpInScope);
                            allTargets.map(empty);
                        } else {
                            allTargets.map(target => {
                                if (targetsByResultId[resultId][0] === target) {
                                    cleanUpInScope(target);
                                    empty(target);
                                }
                            })
                        }

                        targetsByResultId[resultId].map(target => {
                            append(result, {allowScriptTags}, target);
                        });
                        targetsByResultId[resultId].map(initModulesInScope);
                    } else {
                        throwWarning(`@elements/async-append: Response contained entry with unmatched element.
                        Unmatched entry key was "${resultId}".
                        Dom elements were provided for "${Object.keys(targetsByResultId).join(', ')}" `)
                    }
                });
            }


            loading.map(setAttribute('hidden', 'hidden'));
            return result;
        });
}


function hasElements($element) {
    return $element && $element.length;
}