export function setup() {
    window._paq = window['_paq'] || [];
}

export function trackEvent(data, {debug}) {
    let values = Object.values(data.datalayer);

    let matomoData = ['trackEvent'];

    values.map(value => {
        matomoData.push(value);
    });

    _paq.push(matomoData);

    if (debug) {
        console.log('Matomo event tracking - _paq.push:', matomoData);
    }
}