import {onFind} from "@elements/init-modules-in-scope";
import {findIn, closest, on, off, trigger} from "@elements/dom-utils";
import {getPrefixedDataSet} from '@elements/data-set-utils';

const INITIALIZED_EVENT = 'form-validation.initialized';
const SUBMIT_EVENT = 'form-validation.submit';

const defaultOptions = {
    defaultSubmit: true,
    trigger: {event: 'input'}
};

const defaultSelectors = {
    base: '.js-form-validation',
    group: '.js-form-validation__group',
    errors: '.js-form-validation__errors'
};

export function init (options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (form) {
        Promise.all([import('@elements/formvalidation.io/dist/es6'), import('@elements/formvalidation.io/dist/es6/plugins/Bootstrap5'), loadLocales()]).then(([FormValidation, FormValidationBS5, lang]) => {
            createFormValidation(form,
                {...defaultOptions, ...options},
                {...defaultSelectors, ...selectors},
                FormValidation, FormValidationBS5, lang)
        });
    });
}

export async function createFormValidation(baseElement, options= defaultOptions, selectors = defaultSelectors, FormValidation, FormValidationBS5, lang) {
    let api = {
        getFormInstance
    };

    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('form-validation', baseElement)
    };

    let formObject;

    formObject = FormValidation.formValidation(baseElement, {
        localization: lang ? lang.default : null,
        plugins: {
            declarative: new FormValidation.plugins.Declarative({
                html5Input: true,
                prefix: 'data-validation-'
            }),
            bootstrap5: new FormValidationBS5.default({
                rowSelector: selectors.group,
                defaultMessageContainer: false
            }),
            message: new FormValidation.plugins.Message({
                container: function (field, element) {
                    return findIn(selectors.errors, closest(selectors.group, element));
                }
            }),
            trigger: new FormValidation.plugins.Trigger(elementOptions.trigger),
            excluded: new FormValidation.plugins.Excluded(),
            submitButton: new FormValidation.plugins.SubmitButton(),
        }
    }).on('core.form.valid', () => {
        if (elementOptions.defaultSubmit === true) {
            let newAction = baseElement.getAttribute("data-action");
            if (newAction) {
                baseElement.action = newAction;
            }

            baseElement.submit();
        } else {
            trigger(SUBMIT_EVENT, baseElement);
        }
    });

    function getFormInstance() {
        return formObject;
    }

    baseElement.formValidation = api;
    trigger(INITIALIZED_EVENT, baseElement);

    return api;
}

let promise;
export function loadLocales() {
    if (promise) {
        return promise;
    }

    let lang = _config.local || _config.lang;
    if (lang && lang !== 'en') {
        promise = new Promise(function(resolve, reject) {
            import(`@elements/formvalidation.io/dist/es6/locales/${lang}.js`).then(function (data) {
                resolve(data);
            }).catch(function () {
                if (lang !== _config.lang) {
                    /* if the _config.local was used and there is no matching package try to load the package for _config.lang */
                    import(`@elements/formvalidation.io/dist/es6/locales/${_config.lang}.js`).then((data) => {resolve(data)}).catch(() => import('@elements/formvalidation.io/dist/es6/locales/en').then((data) => {resolve(data)}));
                } else {
                    /*fallback if there is no package*/
                    resolve();
                }
            }).catch(function () {
                /*fallback if there is no package*/
                import('@elements/formvalidation.io/dist/es6/locales/en').then((data) => {resolve(data)});
            });
        });
    }

    return promise
}

export function getApi(element) {
    if(element.formValidation) {
        return Promise.resolve(element.formValidation);
    } else {
        return new Promise(function(resolve, reject) {
            function initializeHandler() {
                resolve(element.formValidation);
                off(INITIALIZED_EVENT, initializeHandler, element);
            }
            on(INITIALIZED_EVENT, initializeHandler, element);
        });
    }
}